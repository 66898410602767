<template>
  <div>
    <Topbar></Topbar>
    <div class="evaluate">
      <div class="pc-title">首页>个人中心>我的订单>评价</div>
      <div class="content">
        <div class="content-left">
          <div class="content-left-item">
            <div class="item-img">
              <img :src="detail.SpecImg" alt="" />
            </div>
            <div class="item-font">
              {{ detail.GoodsName }}
            </div>
            <div class="item-font2">{{ detail.SpecName }}</div>
          </div>
        </div>
        <div class="content-right">
          <div class="pingfen">
            <div>商品评分：</div>
            <div>
              <el-rate v-model="stars" show-text> </el-rate>
            </div>
          </div>
          <div class="font">
            <el-form
              :model="ruleForm"
              :rules="rules"
              ref="ruleForm"
              label-width="500px"
              class="demo-ruleForm"
            >
              <el-form-item label="评价">
                <el-input
                  type="textarea"
                  :rows="5"
                  v-model="ruleForm.desc"
                  placeholder="商品是否给力，快来分享一下您的购物心得吧"
                ></el-input>
              </el-form-item>
            </el-form>
          </div>
          <div class="fileup">
            <div class="file-font">晒单</div>
            <van-uploader
              v-model="fileList"
              :after-read="afterRead"
              multiple
              upload-text="添加图片"
            />
          </div>
          <div class="tijiao" @click="postGoodsEvaluationAdd">提交</div>
        </div>
      </div>
    </div>
    <Foter></Foter>
  </div>
</template>
<script>

import Topbar from "../../PCshop/components/topbar";
import Foter from "../../PCshop/components/shopfooter";
import { imagesUploadPic } from "@/api/api";
import { Toast } from "vant";
import {
  queryShopOrderGoodsDetail, goodsEvaluationAdd
} from "@/api/shop";
export default {
  components: {
    Topbar, Foter
  },
  data () {
    return {
      rules: {
        desc: [
          { required: true, message: '请填写活动形式', trigger: 'blur' }
        ]
      },
      ruleForm: {
        desc: ''
      },
      desc: '',
      stars: null,
      value: 1,
      rateValue: 0,
      text: "",
      checked: false,
      detail: {},
      fileList: [],
      imgList: [],
      userInfo: {},
      orderId: 0,
      specId: 0,
    }
  },
  created () {
    if (localStorage.getItem("userInfo") != null) {
      this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
    } else {
      this.userInfo.Id = 0;
    }
    this.orderId = this.$route.query.orderId;
    this.specId = this.$route.query.specId;
    this.getQueryShopOrderGoodsDetail(this.orderId, this.specId);
  },
  methods: {
    async postGoodsEvaluationAdd () {
      let parm = {};
      parm.GoodsId = this.detail.GoodsId;
      parm.StudentId = this.userInfo.Id;
      parm.StudentName = this.userInfo.RealName;
      parm.Score = this.stars;
      parm.Evaluation = this.ruleForm.desc;
      parm.EvaluationImg = this.imgList.join(',');
      parm.IsAnonymous = this.checked ? 1 : 0;
      parm.ShopOrderId = this.orderId;
      parm.SpecId = this.specId;
      parm.SpecName = this.detail.SpecName;
      const res = await goodsEvaluationAdd(parm);
      if (res.success == true) {
        Toast.success({
          message: res.msg,
        });
        this.$router.back(-1)
        // this.$router.push({
        //   path: "/shop/orders/index",
        // });
      } else {
        Toast.fail({
          message: res.msg,
        });
      }
    },

    // 订单商品详情
    async getQueryShopOrderGoodsDetail (orderId, specId) {
      const res = await queryShopOrderGoodsDetail("orderId=" + orderId + "&&specId=" + specId);
      this.detail = res.response;
      console.log(this.detail);
    },
    afterRead (file) {
      // 此时可以自行将文件上传至服务器
      console.log(file.file);
      this.postImagesUploadPic(file.file)
    },
    // 上传文件
    async postImagesUploadPic (file) {
      const formData = new FormData();
      formData.append("file", file);
      const res = await imagesUploadPic(formData);
      if (res.success == true) {
        let url = this.GLOBAL.hostUrl + res.response;
        this.imgList.push(url);
        console.log(this.imgList);
      } else {
        this.$message.error(res.msg);
      }
    },
  }
}
</script>
<style lang='scss' scoped>
.evaluate {
  min-height: calc(100vh - 321px);
}
.pc-title {
  width: 1100px;
  height: 55px;
  margin: 0 auto;
  line-height: 55px;
  color: #666666;
  font-size: 12px;
}
.content {
  display: flex;
  width: 1100px;
  height: 442px;
  background: #ffffff;
  margin: 0 auto 50px;
  border: 1px solid #eeeeee;
  .content-left {
    width: 365px;
    height: 100%;
    border-right: 1px solid #eeeeee;
    display: flex;
    align-items: center;
    justify-content: center;
    .content-left-item {
      text-align: center;
      width: 238px;
      .item-img {
        margin: 0 auto;
        width: 150px;
        height: 150px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .item-font {
        margin-top: 20px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
      }
      .item-font2 {
        font-size: 14px;
        font-weight: 400;
        color: #999999;
        margin-top: 10px;
      }
    }
  }
  .content-right {
    padding-top: 30px;
    padding-left: 39px;
    box-sizing: border-box;
    .pingfen {
      display: flex;
    }
    .font {
      margin-top: 20px;
    }
  }
}
/deep/ .el-form-item__label {
  width: 40px !important;
}
/deep/ .el-form-item__content {
  margin-left: 72px !important;
  width: 560px !important;
}
.fileup {
  display: flex;
  .file-font {
    margin-top: 8px;
    margin-right: 41px;
  }
  .file-img {
    margin-left: 44px;
    width: 120px;
    height: 36px;
    background: #fff1ec;
    border: 1px solid #f95e2e;
    background-image: url("../../../assets/images/servefile.png");
    background-repeat: no-repeat;
    background-position: 22% 56%;
    color: #f95e2e;
    font-size: 14px;
    padding-top: 8px;
    padding-left: 50px;
  }
  .file-font2 {
    font-size: 12px;
    color: #666666;
    margin-left: 20px;
    margin-top: 10px;
  }
}
.tijiao {
  width: 108px;
  height: 38px;
  background: #fa6639;
  color: #ffffff;
  text-align: center;
  line-height: 38px;
  margin-left: 69px;
  margin-top: 20px;
}
</style>